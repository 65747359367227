.personalInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.personalLeft{
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #10142E;
}
.titleRightPart{
    display: flex;
    align-items: center;
}

.personalRight{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #404358;
}

.change{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #404358;
    margin-left: 8px;
}
.personalInfoFilling{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.saveBtn{
    font-family: Inter-Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
    background-color: unset;
    border: none;
    margin-left: 8px;
    display: flex;
    cursor: pointer;
}
.saveBtn:disabled{
    color: #000000;
}
