.part2Whole{
    margin-top: 91px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.iconDiv{
    width: 109px;
    height: 111px;
    margin: 0 auto 14px;
}


.cardText{
    font-family: Inter_Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #8D8C8C;

}