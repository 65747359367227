.backgroundStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:600px;
}

.titleAdditionalPage{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}