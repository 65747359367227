.backgroundStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
}

.titleHomeBlue{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 20px;
    color: #3C78F0;
    text-align: center;
    margin-bottom: 60px;
}

.titleHomePink{
    color: #EB61EB;
    border-bottom: 2px solid #EB61EB;
    margin-left: 25px;
}

.homeSubtitle{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #585858;
    margin-bottom: 60px;
    max-width: 700px;
    width: 100%;
    display: flex;
    align-self: center;
}

.btnsDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.blueBtn{
    margin-right: 30px;
}