.thirdPartWhole{
    background-color:#F3F8FB;
    padding-top: 81px;
    padding-bottom: 81px;
}

.thirdPart{
    max-width: 1080px;
    width: 100%;
    background-color: #FFFFFF;
    padding-top:63px;
    padding-right: 63px;
    margin: auto;
    display: flex;
}

.thirdPartRight{
    display: flex;
    flex-direction: column;
}

.phoneImgDiv{
    margin-right: 50px;
}

.thirdPartTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 32px;
}

.thirdPartText{
    font-family:Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 24px;
    color: #0B2F89;
    margin-bottom:22px;
    white-space: nowrap;
}

.thirdPartText:last-child{
    margin-bottom: 0;
}
