.backgroundStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 328px
}

.sixthTitle{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 48px;
}

.sixthBlueTitle{
    color: #3C78F0;
    margin-left: 15px;
}

.parkingSolutionsBtn {
    padding: 9px 20px;
    background-color: transparent;
    text-align: center;
    color: #FFFFFF;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    border: 2px solid #FFFFFF;
    width: 188px;
    display: flex;
    align-self: center;
    cursor: pointer;
}