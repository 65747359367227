.general{
    margin-bottom: 500px;
}

.generalWhole{
    display: flex;
    padding-top: 160px;
    padding-bottom: 100px;
    justify-content: space-between;
}

.generalLeft{
    display: flex;
    flex-direction: column;
    max-width: 739px;
    width: 100%;
}

.generalTitle{
    font-family: Inter_Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 71px;
}

.generalSubtitle{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #B1B1B1;
    margin-bottom: 18px;
}

.generalText{
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #4E4E4E;
}

.generalRight{
    max-width: 720px;
    width: 100%;
}

.generalQuestion{
    max-width: 720px;
    border: 1px solid #B1B1B1;
    padding: 21px 31px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 24px;
    color: #B1B1B1;
    margin-bottom: 13px;
    cursor: pointer;
}

.generalQuestion:is(:hover,:focus){
    border: 1px solid #3C78F0;
    color: #3C78F0;
}

.backgroundStyleContact{
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 425px;
    padding-top: 82px;
}
