.submitVehicle{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    background: #205ECA;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.submitVehicle:disabled{
    background-color: grey;
}

.inputWholeDiv{
    display:flex;
    flex-direction: column;
    margin-bottom: 23px;
}

.labelSelect{
    display: block;
    font-family: Inter_Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
    margin-bottom: 4px;
}

.inputSelect {
    padding: 14px 13px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16145F;
    background: #FFFFFF;
    border: 1px solid #B1B1B1;
}

.vehicleWhole{
    height: 250px;
    overflow-y: auto;
    margin-bottom: 70px;
}

.vehicleDiv{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #404358;
}