.modalWhole{
    padding-left: 32px;
    padding-right:32px;
    padding-bottom:40px;
}

.modalTitle{
    font-family: Inter-Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #171717;
    text-align: center;
    margin: 120px auto 25px;
}

.modalSubTitle{
    font-family: Inter-Regular,sans-serif;
    margin-bottom: 66px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #727272;
}

.resetPasswordBtn{
    font-family: Inter-Medium,sans-serif;
    max-width: 633px;
    width: 100%;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;
    background: #3C78F0;
    border-radius: 2px;
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.resetPasswordBtn:disabled{
    cursor: not-allowed;
}
.backToSign{
    font-family: Inter-Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3C78F0;
    text-align: center;
    cursor: pointer;
}

.forgetPasswordError{
    color: red;
    text-align: center;
    margin-top: 5px;
}

.btnErrorDiv{
    margin-bottom: 60px;
}