.control{
    margin-bottom: 12px;
    /*width: 100%;*/
}

/*.control input,*/
.control label {
    display: block;
    font-family: Inter_Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
    margin-bottom: 4px;
}

.inputDiv{
    position: relative;
}

.control input,
.select {
    padding: 14px 13px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16145F;
    background: #FFFFFF;
    border: 1px solid #B1B1B1;
    /*max-width: 505px;*/
    /*width: 100%;*/
}

.control input:focus{
    outline: none;
    color:  #16145F;
}

.control input::placeholder{
    color: #B1B1B1;
}

.invalid{
    border: 2px solid rgba(249, 63, 63, 1) !important;
}

.textDanger {
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    color: rgba(249, 63, 63, 1);
    margin-top: 4px;
    width: 505px;
}

.inputDiv img{
    width: 25px;
    position: absolute;
    bottom: 11px;
    right: 35px;
    cursor:pointer;
}

