.backgroundStyle{
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    height:223px;
    padding-top: 38px;
    margin-bottom: 100px;
}

.titlePart3{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 32px;
}

.subTitlePart3{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.bluePartBtn{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    padding: 13px 15px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #0B2F89;
    width: 260px;
    margin: auto;
    cursor: pointer;
}

.callBtn{
    width: 397px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 26px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 20px;
    color: #FFFFFF;
    background: #3C78F0;
    cursor: pointer;
    margin: 0 auto 100px;
    border: none;
    border-radius: 8px;
}

