.modalWhole{
    display: flex;
    justify-content: space-between;
}

.loginImg{
    height: 100%;
}

.loginInformation{
    display: flex;
    flex-direction: column;
    padding: 215px 60px 0 125px;

}
.titleLogin{
    font-family: Inter-Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 24px;
    color: #424242;
    white-space: nowrap;
    margin-bottom: 26px;
}

.subtitleLogin{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #A0A0A0;
    margin-bottom: 40px;
}

.rememberForget{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

.forgotPassword{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #A0A0A0;
    cursor: pointer;
}

.loginBtn{
    background: #3C78F0;
    border-radius: 2px;
    max-width: 684px;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.loginBtn:disabled{
    cursor: not-allowed;
}

.loginBelow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
}

.loginLeft{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A0A0A0;
    cursor: pointer;
}

.loginRight{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A0A0A0;
    cursor: pointer;
}

.loginSpan{
    text-decoration-line: underline;
    margin-left:5px
}

.loginError{
    color: red;
    text-align: center;
    margin-top: 5px;
}





