.modalSignUp{
    margin: auto;
    padding-top: 85px;
    padding-bottom: 85px;
}

.signUpTitle{
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 70px;
    text-align: center;
}

.signUpFormRow{
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    width: 100%;
}

.registerBtn{
    background: #3C78F0;
    border-radius: 2px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    max-width:416px;
    width: 100%;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    margin: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.registerBtn:disabled{
    cursor: not-allowed;
}


.marginRight{
    margin-right: 16px;
}

.signUpError{
    color: red;
    text-align: center;
    margin-top: 5px;
}


