.submitCard{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    background: #205ECA;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.submitCard:disabled{
    background-color: grey;
}

.cardsWhole{
    height: 200px;
    overflow-y: auto;
    margin-bottom: 70px;
}

.cardDiv{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #404358;
}