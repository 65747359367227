.solutionsPart1Whole{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 108px;
    padding-top:10px
}

.solutionsPart1Left{
    display: flex;
    flex-direction: column;
}

.solutionsTitle{
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 24px;
    color:#424242;
    margin-bottom: 40px;
}

.solutionsSubTitle{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 24px;
    color: #424242;
    margin-bottom: 74px;
}

.solutionsText{
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 39px;
    max-width: 673px;
    width: 100%;
}