.part1Whole{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 120px;
    margin-top: 50px;
}

.serviceDiv{
    max-width: 729px;
    width: 100%;
    margin-top: 36px;
}

.serviceNameDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D2D3D6;
    border-top: none;
    border-radius: 0px 0px 4px 4px;
    padding: 23px 43px;
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 30px;
    color: #0B2F89;
}

.plusDiv{
    cursor: pointer;
}

.stepsWhole{
    display: flex;
    justify-content: space-between;
    position: absolute;
    margin-top: -100px;
    margin-bottom: 25px;
}

.iconDiv{
    width: 99px;
    margin: 0 auto 45px;
}

.cardName{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    margin: 0 auto 15px;
    color: #0B2F89;
    max-width: 390px;
}

.backgroundWave{
    position: relative;
    height: 420px;
    width: 100%;
    margin-top:250px
}