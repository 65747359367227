.reservationsWhole{
    background: #FFFFFF;
    padding: 24px 32px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-width: 1720px;
    width: 100%;
    margin: 24px auto 0;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
}

.titleReservations{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #10142E;
    padding-bottom: 24px;
    border-bottom:1px solid #B7B9C0;
}

.reservationsText{
    display: flex;
    align-self: center;
    margin: auto;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #10142E;
}

.reserveCard{
    background: #FFFFFF;
    border: 2px solid #E7E7EA;
    border-radius: 4px;
    max-width: 814px;
    width: 100%;
    padding: 16px 24px 32px 24px;
    margin-top: 24px;
}

.reserveCardClosed{
    background: #FFFFFF;
    opacity: 0.5;
    border: 2px solid #E7E7EA;
    border-radius: 4px;
    max-width: 814px;
    width: 100%;
    padding: 16px 24px 32px 24px;
    margin-top: 24px;
}

.reservationsPart{
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
}

.reservedPlace{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    margin-bottom: 8px;
}

.reservedAddress{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #282C43;
    margin-bottom: 16px;
}

.arrangement{
    padding-top: 24px;
    padding-bottom: 24px;
    border-top:1px solid #B7B9C0;
    border-bottom:1px solid #B7B9C0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.arrivalLeaving{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #205ECA;
}

.arrivalLeavingPart{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #205ECA;
    margin-right: 8px;
}
.arrivalLeavingDay{
    margin-right: 8px;
}

.order{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #10142E;
}

