.headerWhole{
    background: #FFFFFF;
    padding: 14px 91px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

.logo{
    cursor: pointer;
}

.headerPart2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_link{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #3E3D3D;
    margin-right: 52px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}

.nav_link:hover{
    color:#EB61EB;
}

.activePinkBtn{
    margin-left: 28px;
    background: #EB61EB;
    border-radius: 2px;
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 11px;
    cursor: pointer;
}

.whiteArrow{
    margin-left: 22px;
}

.nav_link_active{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color:#3C78F0;
    text-decoration: none;
    margin-right: 52px;
    white-space: nowrap;
}

.user{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    margin-right: 30px;
}

.menuBtn{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.header_dropDown_content{
    background: white;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 180px;
    padding: 10px 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    z-index: 999;
}

.logOut{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #3E3D3D;
    margin-right: 52px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}

.logOut:hover{
    color:#EB61EB;
}
