.profileWhole{
    display: flex;
}
.profileLeft{
   width: 20%;
}

.profileRight{
    flex: 1;
    width: 80%;
}
.apply{
    border-top: 1px solid #B7B9C0;
    border-bottom: 1px solid #B7B9C0;
    padding: 0 16px;
    background: #FFFFFF;
    border-bottom: 1px solid #B7B9C0;
    display: flex;
    align-items: center;
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    border-bottom: 1px solid #B7B9C0;
}

.filter{
    margin-right: 12px;
}

.filtersBelow{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #B7B9C0;
}

.events{
    width: 50%;
    background: #FFFFFF;
    padding-top:24px;
    padding-bottom: 24px;
    text-align: center;
    border-right:1px solid #B7B9C0;
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    cursor: pointer;
}


.parkings{
    width: 50%;
    background: #FFFFFF;
    padding-top:24px;
    padding-bottom: 24px;
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    text-align: center;
    cursor: pointer;
}

.greyBackground{
    background: #F3F3F5;
}

.renderEvents{
    overflow-y: scroll;
    height: 770px
}

.eventsParkingsAll{
    background: #FFFFFF;
    padding:16px 24px;
    display: flex;
    border-bottom: 1px solid #B7B9C0;
}

.eventsParkingsLeft{
    max-width: 160px;
    width: 100%;
    height: 160px;
}

.eventParkingImage{
    width: 160px;
    height: 160px;
}
.eventsParkingsRight{
    margin-left: 12px;
    max-width: 284px;
    width: 100%;
}

.eventsTitle{
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #10142E;
    margin-bottom: 12px;
}

.eventsPlace{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #10142E;
    margin-bottom: 12px;
}

.eventsStartDate{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #10142E;
    margin-bottom: 15px;
}

.eventsEndDate{
    margin-left: 5px;
}

.btn{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    background: #205ECA;
    border-radius: 8px;
    max-width: 284px;
    width: 100%;
    padding-top:4px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ticket{
    margin-right: 5px;
}

.location{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #707282;
    margin-bottom: 12px;
}

.map{
    margin-right: 4px;
    width: 20px;
    height: 20px;
}

.namePrice{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.name{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #10142E;
}

.price{
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #205ECA;
}

.parkingsIcons{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.parkingIcon{
    margin-right: 8px;
    cursor: pointer;
}


.parkingBtns{
    display: flex;
}

.detailsBtn{
    max-width: 140px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border: 1px solid #205ECA;
    border-radius: 8px;
    margin-right: 4px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #282C43;
}

.detailsBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

.reserveBtn{
    max-width: 140px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border: none;
    background: #205ECA;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.reserveBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}


.iconButton{
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: 42px;
    white-space: nowrap;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;
    color: #10142E;
}

.iconButtonNotActive{
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: 42px;
    white-space: nowrap;
    padding-top: 18px;
    padding-bottom: 18px;
    color: darkgrey;
    cursor: not-allowed;
}


.iconButton:hover{
   color: #205ECA;
}

.icon{
    margin-right: 12px;
}

.icon_left{
    margin-left: 12px;
}

.parkingZone{
    display: flex;
    flex-direction: column;
}

.zoneAddress{
    color: #10142E;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    max-width: 327px;
    width: 100%;
    margin-bottom: 24px;
    margin-top:24px;
    margin-left: 16px;
}

.parkingZoneImg{
    max-width: 507px;
    width: 100%;
}

.title{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #10142E;
    border-bottom: 1px solid #E7E7EA;
    padding: 16px 15px;
}

.evChargeImg{
    margin-right: 8px;
}

.evCharge{
    padding: 21px 16px 37px 16px;
    border-bottom: 1px solid #E7E7EA;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #282C43;
    display: flex;
    align-items: center;
}

.respondEvCharge{
    margin-left: 15px;
}

.locationDetails{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #282C43;
    margin-top: 16px;
    margin-bottom: 33px;
    max-width: 450px;
    width: 100%;
}

.hoursData{
    margin-top: 16px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #282C43;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
}
.buttonBox{
    padding-top: 21px;
    padding-bottom: 21px;
    background: #FFFFFF;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
}