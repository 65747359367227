.contactForm{
    max-width:1080px;
    width: 100%;
    padding: 50px 25px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 75px;
}

.contactSubtitle{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #B1B1B1;
    margin-bottom: 13px;
}

.contactTitle{
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 30px;
}

.contactFormRow{
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
}

.contactFormRow .errorAndInput >input {
    padding: 15px 16px;
    border: 1px solid #B1B1B1;
    margin-bottom: 13px;
    width:505px;
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: black
}

.contactFormRow .errorAndInput >input:focus{
    outline:none
}

.errorDiv{
    color: red;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 11px;
}

.invalid{
    border: 2px solid rgba(249, 63, 63, 1) !important;
    padding: 15px 16px;
    width:1026px;
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
}

.bigInput{
    padding: 15px 16px;
    border: 1px solid #B1B1B1;
    margin-bottom: 13px;
    width:1026px;
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: black
}

.bigInput:focus{
    outline: none;
}

.formHome .textAreaHomePage >textarea{
    padding: 15px 16px;
    border: 1px solid #B1B1B1;
    margin-top: 10px;
    width:1026px;
    font-family: Inter_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16145F;
}

.formHome .textAreaHomePage >textarea:focus{
    outline: none;
}

.btnDiv{
    display: flex;
    justify-content: flex-end;
}

.sendBtn{
    background: #0B2F89;
    border-radius: 2px;
    width: 161px;
    padding-top: 11px;
    padding-bottom: 11px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.sendBtn:disabled{
    cursor:not-allowed;
}

.textDanger{
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    color: rgba(249, 63, 63, 1);
    width: 1026px;
    margin-top: 4px;
}

.repairStyle{
    font-family: Inter-Regular, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: black;
    margin: 0 auto 100px;
}