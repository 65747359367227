* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}


body{
  background-color:#FFFFFF;
  min-height: 100vh;
}

html{
  scroll-behavior: smooth;
}

.container{
  max-width: 1538px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.blackTitle{
  font-family: Inter_Bold, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.max_widthSwiperDuration{
  max-width: 1000px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.swiper-wrapper{
  max-width:800px;
  margin-bottom: 50px;
}
.react-time-picker__inputGroup{
  height: 50px;
}

.react-calendar{
  border-radius: 5px;
}
.card{
  width: 476px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  padding:40px 20px 100px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.marginRight{
  margin-right: 59px;
}

.card:hover{
  box-shadow: 0px 4px 8px #0B2F89;
}

.cardTitle{
  font-family: Inter_Bold, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: #0B2F89;
  white-space: nowrap;
}

.settingsWhole{
  max-width: 818px;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #E7E7EA;
  border-radius: 4px;
  height: max-content;
  min-height: 500px;
  padding: 16px 24px 24px 24px;
}

.settingsTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #B7B9C0;
}

.titleDetails{
  font-family: Inter-Bold, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #10142E;
}

.edit{
  font-family: Inter-Medium, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 8px;
  display: flex;
  cursor: pointer;
}

.editImg{
  margin-right: 8px;
}

.notification{
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 32px;
  color: #707282;
  margin-top: 85px;
  text-align: center;
}

.blueBtn{
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 332px;
  width: 100%;
  text-align: center;
  background-color: #205ECA;
  border-radius: 4px;
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  margin: auto;
  cursor: pointer;
}

.header_dropDown_content {
  background: white;
  min-height: 250px;
  position: absolute;
  top: 100%;
  right: 4%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 300px;
  padding: 20px 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  z-index: 999;
}

.max_width{
  max-width: 507px;
  width: 100%;
}

.max_width .swiper-button-next:after, .swiperOffer .swiper-rtl .swiper-button-prev:after {
  font-size: 28px
}
.max_width .swiper-button-prev:after{
  font-size: 28px
}

@font-face {
  font-family: Inter_Light;
  src: url(app/assets/fonts/Inter/Inter_Light.ttf);
}

@font-face {
  font-family: Inter_Thin;
  src: url(app/assets/fonts/Inter/Inter_Thin.ttf);
}

@font-face {
  font-family: Inter_Regular;
  src: url(app/assets/fonts/Inter/Inter_Regular.ttf);
}

@font-face {
  font-family: Inter_Medium;
  src: url(app/assets/fonts/Inter/Inter_Medium.ttf);
}

@font-face {
  font-family: Inter_SemiBold;
  src: url(app/assets/fonts/Inter/Inter_SemiBold.ttf);
}

@font-face {
  font-family: Inter_Bold;
  src: url(app/assets/fonts/Inter/Inter_Bold.ttf);
}

@font-face {
  font-family:Inter_ExtraBold;
  src: url(app/assets/fonts/Inter/Inter_ExtraBold.ttf);
}
