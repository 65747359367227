.reserveParkingWhole{
    display: flex;
}

.reserveLeft{
    width: 80%;
}

.reserveUp{
    background: #F3F8FB;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    height: 360px;
}

.reserveTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 26px;
    color: #3C78F0;
}

.reserveText{
    font-family: Inter_Regular,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #606060;
    margin-bottom: 100px;
    max-width: 560px;
    width: 100%;
}

.illustrationImg{
    max-width: 602px;
}

table{
    padding: 20px 20px 20px 20px;
    margin-top: 50px;
    border-top: 2px solid #E9EFFD;
    margin-left: 30px;
    max-width: 1430px;
    width: 100%;
}

tbody{
    max-width: 1430px;
    width: 100%;
}

tr{
    max-width: 1430px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

tr,td{
    padding: 10px 15px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: #9D9E9F;
}
.grey{
    background: #E9EFFD;
    border-radius: 15px;
    width: 36px;
    height: 21px;
    cursor: pointer;
}

.greyCursor{
    background: #E9EFFD;
    border-radius: 15px;
    width: 36px;
    height: 21px;
    cursor: not-allowed;
}

.blue{
    background: #3C78F0;
    border-radius: 15px;
    width: 36px;
    height: 21px;
    cursor: not-allowed;
}

.purpleCursor{
    background: #E545E5;
    border-radius: 15px;
    width: 36px;
    height: 21px;
    cursor: not-allowed;
}

.purple{
    background: #E545E5;
    border-radius: 15px;
    width: 36px;
    height: 21px;
    cursor: pointer;
}
.notAllowedText{
    text-align: center;
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px;
    margin-bottom: 26px;
    color:#606060;
}

.colorsWholeDiv{
    display: flex;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: 30px;
}
.colorsDiv{
    display: flex;
    align-items: center;
    margin-right:68px;
}
.colorType {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #606060;
}

.colorsItemBlue{
    background: #3C78F0;
    border-radius: 15px;
    width: 88px;
    height: 30px;
    margin-right: 17px;
}

.colorsItemPurple{
    background: #E545E5;
    border-radius: 15px;
    width: 88px;
    height: 30px;
    margin-right: 17px;
}

.colorsItemGrey{
    background-color: #E9EFFD;
    border-radius: 15px;
    width: 88px;
    height: 30px;
    margin-right: 17px;
}

.reserveRight{
    background-color:#F3F8FB;
    margin-left: 13px;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reserveRightUp{
    padding-top: 53px;
    padding-left: 20px;
}

.inputWholeDiv{
    display:flex;
    flex-direction: column;
    margin-bottom: 23px;
}

.labelSelect{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #282C43;
    margin-bottom: 15px;
}

.inputSelect {
    padding: 9px 11px;
    width: 343px;
    font-family: Inter-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.24px;
    color: #282C43;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
}

.inputDiv:focus{
    outline: none;
}

.inputDiv::placeholder{
    color: #B8C0CC;
}

.parkingInformationWhole{
    width: 100%;
    background-color: #3C78F0;
    padding: 60px 20px;
    border-radius: 10px;
}

.parkingInformationTitle{
    font-family: Inter-Medium,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px;
    color: #FFFFFF;
    padding-bottom: 24px;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 50px;
}

.parkingInformation{
    font-family: Inter-Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.totalPriceDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 42px;
}

.total{
    font-family: Inter-Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.totalPrice{
    font-family: Inter-Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.reserveBtn{
    max-width: 343px;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    background: #FFFFFF;
    border-radius: 2px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3C78F0;
    text-align: center;
    margin: auto;
    cursor: pointer;
}

.reserveDown{
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-top: 200px;
}

.hoursDiv{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 240px !important;
    width: 100%;
}

.hoursNewDiv{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
}
.startTime{
    font-family: Inter_Bold, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #606060;
    margin-bottom: 4px;
    text-align: center;
    margin-top: 30px;
}

.startTimeInput{
    padding: 13px 16px;
    border: 2px solid #F3F8FB;
    border-radius: 10px;
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #606060;
    cursor: pointer;
}

.startTimeInput:focus{
    outline: none;
}

.priceDiv{
    font-family: Inter_Medium, sans-serif;
    padding: 13px 24px;
    border: 2px solid #F3F8FB;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3C78F0;
}

.priceSpan{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #606060;
    margin-left: 30px;
}

.calendarDiv{
    padding-left: 100px;
}

.durationWhole{
    width: 80px !important;
    height: 70px;
    border-radius: 10px;
    background: #F3F8FB;
    padding: 13px 15px;
    display: flex;
    max-width: 600px;
}

.durationWholeActive{
    width: 80px !important;
    height: 70px;
    border-radius: 10px;
    background: #3C78F0;
    padding: 13px 15px;
    display: flex;
    max-width: 600px;
}

.durationDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
}

.parkingBtn{
    max-width: 300px;
    width: 100%;
    height: 50px;
    background:#3C78F0;
    border-radius: 2px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color:  #FFFFFF;
    text-align: center;
    margin: 40px auto 0;
    cursor: pointer;
    border:none;
}

.inputDuration{
    height: 50px;
    padding: 0 2px;
    box-sizing: border-box;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.middle{
    margin-left: 50px;
    max-width: 240px !important;
    width: 100%;
}


.inputDuration:focus{
    outline: none;
}



.error{
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    color: rgba(249, 63, 63, 1);
    margin-top: 4px;
}

.timeStyle{
    max-width: 240px !important;
    width: 100%;
}

.errorStyle{
    border: thin solid rgba(249, 63, 63, 1);
}