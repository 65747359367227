.repairRequest{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 24px;
    color: #000000;
    margin: 100px auto 110px;
    text-align: center;
}

.formStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1026px;
    margin: auto;
}

.inputDiv{
    display: flex;
    gap: 15px;
    width: 100%;
    max-width: 1026px;
}

.selectDiv{
    display: block;
    margin-bottom: 32px;
    width: 100%;
}

.selectInput{
    width: 505px;
    padding: 14px 13px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
    background: #FFFFFF;
    border: 1px solid #B1B1B1;
    height: 54px;
}
/*.selectInput::placeholder{*/
/*    color: #B1B1B1;*/
/*}*/

/*option:not(:first-of-type) {*/
/*    color: blue;*/
/*}*/
/*option:not(:checked) {*/
/*    color:orange*/
/*}*/
/*.selectInput option::placeholder{*/
/*    color: red;*/
/*}*/

.selectInputRed{
    width: 505px;
    padding: 14px 13px;
    font-family: Inter_Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
    background: #FFFFFF;
    border: 1px solid red;
    height: 54px;
}
.repairStyle{
    font-family: Inter-Regular, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: black;
    margin: 0 auto 100px;
}


.selectInput:focus{
    outline: none;
    color:  #16145F;
}
.selectInput::placeholder{
    color: #B1B1B1;
}

.textDangerServices {
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: rgba(249, 63, 63, 1);
    margin-top: 4px;
    width:100%
}

.buttonsDiv{
    margin: 0 auto 20px;
    display: flex;
}

.leftBtn{
    cursor: pointer;
    margin-right: 13px;
    width: 244px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background-color:#3C78F0;
    border-radius: 2px;
    text-align: center;
    border:none;
}

.leftBtn:disabled{
    cursor: not-allowed;
}
.rightBtn{
    cursor: pointer;
    width: 244px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    background-color: #FFFFFF;
    color: #3C78F0;
    text-align: center;
    border: 1px solid #0B2F89;
    border-radius: 2px;
}

