.backgroundStyle{
    background-repeat: no-repeat;
    background-size: cover;
    height:600px;
}


.backgroundBlueStyle{
    background-repeat: no-repeat;
    background-size: cover;
    height:600px;
    position: relative;
    margin-top: 800px;
}


.contactWhole{
    position: absolute;
    bottom: 300px;
    display: flex;
    justify-content: space-between;
    max-width: 1548px;
    width: 100%;
    padding: 55px 25px 80px 100px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}


.contactLeft{
    display: flex;
    flex-direction: column;
}

.leftTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 24px;
    color: #424242;
    margin-bottom: 100px;
}

.iconAndInformation{
    display: flex;
    align-items: center;
    margin-bottom: 65px;
}

.informationDiv{
    margin-left:18px;
}

.infoTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 24px;
    color: #424242;
}

.content{
    font-family: Inter-Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    margin-top: 14px;
    color: #000000;
    max-width: 522px;
    width: 100%;
}

.contactRight{
    display: flex;
    flex-direction: column;
}


.rightTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 24px;
    color: #424242;
    margin-bottom: 100px;
}

.rightForm{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.rightForm .textAreaDiv >textarea{
    padding: 15px 16px;
    border: 1px solid #B1B1B1;
    margin-top: 10px;
    width:720px;
    font-family: Inter_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16145F;
}

.rightForm .textAreaDiv >textarea:focus{
    outline: none;
}

.invalid{
    border: 2px solid rgba(249, 63, 63, 1) !important;
}

.textDanger{
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: rgba(249, 63, 63, 1);
    margin-top: 4px;
    width: 720px
}

.btnDiv{
    display: flex;
    justify-content: flex-end;
}

.sendBtn{
    background: #3C78F0;
    border-radius: 2px;
    width: 213px;
    padding-top: 11px;
    padding-bottom: 11px;
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    margin-top: 13px;
}

.sendBtn:disabled{
    cursor: not-allowed;
}
.repairStyle{
    font-family: Inter-Regular, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: black;
    margin: 0 auto 100px;
}