.fourthWhole{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 57px;
    padding-top: 119px;
}

.fourthTitle{
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 44px;
}

.fourthSubTitle{
    font-family:Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 50px;
}

.fourthText{
    font-family:Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    max-width: 850px;
    width: 100%;
    text-align: center;
}