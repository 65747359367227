.backgroundStyle{
    display: flex;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    height:694px;
    margin-bottom: 90px;
}

.solutionsInside {
    display: flex;
    flex-direction: column;
    max-width: 645px;
    width: 100%;
    margin-top: 31px;
    margin-right: 128px;
    margin-bottom: 42px;
}

.inside1{
    padding:16px 25px;
    background-color: #3C78F0;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.inside1Text{
    font-family: Inter-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 30px;
}

.inside2{
    background: #FFFFFF;
    padding: 23px 10px 23px 78px;
    font-family: Inter-Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4E4E4E;
}

.inside3{
    background: #F4F4F4;
    padding: 22px 12px;
    display: flex;
    align-items: center;
}

.inside3Text{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-left: 15px;
    color: #424242;
}

.btn{
    text-align: center;
    margin-bottom: 100px;
}