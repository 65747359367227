.yellowButton{
    border-radius: 2px;
    padding: 21px 31px;
    border: none;
    cursor: pointer;
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    /*z-index:10;*/
    min-width: 183px;
}

.yellowButton:disabled{
    cursor: not-allowed;
}
