.footerWhole{
    padding-top: 100px;
    padding-bottom: 76px;
}

.footerInside{
    display: flex;
    justify-content: space-between;
}

.footerFirstPart{
    display: flex;
    flex-direction: column;
}

.firstPartRow{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.firstPartRow:last-child{
    margin-bottom: 58px;
}

.titleAndDetail{
    display: flex;
    flex-direction: column;
    margin-left:16px;
}

.title{
    font-family: Inter_SemiBold,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 7px;
}

.detail{
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
}

.footerSecondPart{
    display: flex;
    flex-direction: column;
}

.footerSecond{
    font-family: Inter_Light,sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-bottom: 20px;
}