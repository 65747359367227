.swiperDiv{
    max-width: 507px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.parkingZoneImg{
    max-width: 450px;
    max-height: 250px;
    width: 100%;
}