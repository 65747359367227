.settingsWhole{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 24px 32px 20px 32px;
    margin-top: 24px;
}

.settingsRow{
    display: flex;
    gap:20px;
    justify-content: space-between;
    margin-bottom: 24px;
}

.titleSettings{
    margin-bottom: 24px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #10142E;
}


