.headerWhole{
    background: #FFFFFF;
    padding: 14px 91px;
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
}

.logo{
    cursor: pointer;
}

.headerPart2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_link{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
    color: #3E3D3D;
    margin-right: 52px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}

.nav_link:hover{
    color:#EB61EB;
}

.activePinkBtn{
    display: flex;
    justify-content: center;
    margin-left: 28px;
    background: #EB61EB;
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    align-items: center;
    padding: 11px;
    width: 150px;
    cursor: pointer;
    border-radius: 5px;
}

.whiteArrow{
    margin-left: 22px;
}

.nav_link_active{
    font-family: Inter_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color:#3C78F0;
    text-decoration: none;
    margin-right: 52px;
    white-space: nowrap;
}

