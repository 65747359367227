.submitButton{
    background: #205ECA;
    border-radius: 4px;
    padding: 16px 130px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.submitButton:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

.submitButton:disabled{
    cursor: not-allowed;
}
