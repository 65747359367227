.control{
    margin-bottom: 16px;
}

.control input,
.control label {
    display: block;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #10142E;
    margin-bottom: 4px;
}

.inputDiv{
    position: relative;
}

.control input,
.select {
    border: 1px solid #B7B9C0;
    border-radius: 4px;
    background: #FFFFFF;
    padding: 15px 12px;
    font-family:Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    max-width: 684px;
    width: 100%;
}

.control input:focus{
    outline: none;
    color: #000000;
}

.control input::placeholder{
    color: #BFBFBF;
}

.invalid{
    border: 2px solid red !important;
}

.textDanger{
    font-family: Inter-Regular, sans-serif;
    font-size: 14px;
    color: red;
    margin-top: 10px;
    max-width: 470px;
    width: 100%;
}

.inputDiv img{
    width: 20px;
    position: absolute;
    bottom: 15px;
    right: 35px;
    cursor: pointer;
}
