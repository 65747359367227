.emailText{
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-bottom: 26px;
    margin-top:34px
}

.imgStyle{
    margin-right: 8px;
}

.email{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notificationDiv{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #B7B9C0;
    margin-bottom: 10px;
}

.notificationsLeft{
    width: 60%;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.notificationsMiddle{
    width: 20%;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B7B9C0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notificationsRight{
    width: 20%;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B7B9C0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.green{
    font-family: Inter_Bold, sans-serif;
    font-weight: 600;
    color: #3CA854;
}

.checkBoxLabel{
    width: 20%;
    font-family: Inter_Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color:#10142E;
    text-align: center;
    display: flex;
    align-items: center;
}



.checkboxStyle{
    width: 16px;
    height: 16px;
    margin-right: 8px;
}


.titleRightPart{
    display: flex;
    align-items: center;
}

.saveBtn{
    font-family: Inter-Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    background-color: unset;
    border: none;
    margin-left: 8px;
    display: flex;
    cursor: pointer;
}


