.imgStyle{
    margin: 80px auto 32px;
    max-width: 143px;
    width: 100%;
    height: 204px;
}

.successWhole{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.title{
    color: #101828;
    font-family: Inter_SemiBold,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 32px;
    text-align: center;
}

.subtitle{
    font-family: Inter_SemiBold,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color:#667085;
    text-align: center;
}