.modalWhole{
    display: flex;
}
.modalRight{
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    cursor: pointer;
}

.close{
    font-family: Inter-Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 2px;
}


.modalTitle{
    padding-bottom: 15px;
    font-family: Inter-Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #10142E;
    border-bottom: 1px solid #B7B9C0;
    margin-bottom: 16px;
}

.addCarForm{
    display: flex;
    flex-direction: column;
}

.addCar{
    display: flex;
    align-self: flex-end;
    background: #205ECA;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px 71px;
    text-align: center;
    font-family: Inter-Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
}

.addCar:disabled{
    background-color: grey;
}

.inputWholeDiv{
    display:flex;
    flex-direction: column;
    margin-bottom: 23px;
}

.labelSelect{
    display: block;
    font-family: Inter_Light, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B1;
    margin-bottom: 4px;
}

.inputSelect {
    padding: 14px 13px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16145F;
    background: #FFFFFF;
    border: 1px solid #B1B1B1;
}